<template>
  <Page class="page-pricings page-new-pricing">
    <template #title>Nuovo pricing</template>

    <template #title-extra>
      <router-link class="btn btn-outline-light" :to="{ name: 'pricings.list' }">Indietro</router-link>
    </template>

    <template #default>
      <PricingForm :feedback.sync="feedback" @submitForm="submit" />
    </template>
  </Page>
</template>

<script>

import Page from '@/views/components/Private/Page.vue';
import PricingForm from '@/views/components/Form/PricingForm.vue';

export default {
  components: {
    Page,
    PricingForm,
  },
  data: () => ({ feedback: null }),
  methods: {
    submit (pricingForm) {
      const pricing = { ...pricingForm };

      this.$api.createPricing(pricing)
        .then(res => {
          this.feedback = true;
          this.$router.push({ name: 'pricings.show', params: { id: res.data.id } });
        })
        .catch(err => {
          this.feedback = false;
          this.$log.error(err);
        })
      ;
    },
  },
};

</script>
